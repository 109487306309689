import { NEXT_API_ROUTES } from "@/constants/routes";
import { logger } from "@/utils/logger";
import { useApolloClient } from "@apollo/client";
import axios from "axios";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { toast } from "./useToast";

const useSignOut = () => {
  const client = useApolloClient();
  const router = useRouter();
  const { t } = useTranslation();

  const handleSignOut = async () => {
    try {
      await axios.post(NEXT_API_ROUTES.LOGOUT);

      client.cache.modify({
        fields: {
          me(_, { DELETE }) {
            return DELETE;
          },
          userReservations(_, { DELETE }) {
            return DELETE;
          },
        },
      });

      toast({ title: t("logoutSuccess") });
      router.reload();
    } catch (error) {
      logger(error);
      toast({ title: t("errorOccurred"), variant: "destructive" });
    }
  };

  return handleSignOut;
};

export default useSignOut;
