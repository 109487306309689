export const ROUTES = {
  HOMEPAGE: "/",
  GUEST: "/guest",
  GUEST_EDIT: "/guest/edit",
  SERVER_ERROR: "/500",
  NOT_FOUND: "/not-found",
  THANK_YOU: "/thank-you",
  SIGN_IN: "/sign-in",
  RESERVATION: "/reservation",
  RESERVATIONS: "/reservations",
  FAQ: "/faq",
  ACCOUNT: "/account",
  ACCOUNT_SETTINGS: "/account/settings",
  ACCOUNT_SETTINGS_NOTIFICATIONS: "/account/settings/notifications",
  ACCOUNT_SETTINGS_LANGUAGE: "/account/settings/language",
  ACCOUNT_SETTINGS_SSO: "/account/settings/sso",
  ACCOUNT_PROFILE: "/account/profile",
  MAGIC_PAGE: "/sign-in/magic",
  DEVICES: "/devices",
  HOTELS: "/hotels",
  PAYMENT_STATUS: "/payment/status",
  ERROR: "/error",
  DISCOVERY: "/discovery",
};

export const SUB_ROUTES = {
  PRECHECKIN: "/precheckin",
  PRODUCTS: "/products",
} as const;

export const EXTERNAL_URLS = {
  OREA_WEB: "https://orea.cz",
  CALENDAR_OREA: "https://calendar.orea.cz",
  OREA_BOOKING_PATH: "hotely-apartmany?adultsCount=2",
};

export const NEXT_API_ROUTES = {
  CONFIRM_USER: "/api/confirm-account",
  LOGOUT: "/api/logout",
  MAGIC_LOGIN: "/api/login/magic",
  UPDATE_DEVICE: "/api/update-device-status",
  VERIFY_RESERVATION: "/api/reservation/verify-and-redirect",
  VERIFY_HMAC: "/api/reservation/verify-hmac",
  RESERVATION_LOGIN: "/api/login/reservation",
  GENERATE_JWT: "/api/reservation/generate-jwt",
  SCAN_LIMITER: "/api/scan-limiter",
  CREATE_SOCIAL_USER: "/api/login/create-user-by-social",
  MERGE_ACCOUNT: "/api/login/merge-account",
  AUTHENTICATE: "/api/authenticate",
};

/**
 * An array of routes that are accessible to the public
 * These routes do not require authentication
 * @type {string[]}
 */
export const publicRoutes = [
  ROUTES.SIGN_IN,
  ROUTES.SERVER_ERROR,
  ROUTES.NOT_FOUND,
  NEXT_API_ROUTES.MAGIC_LOGIN,
  ROUTES.MAGIC_PAGE,
  ROUTES.FAQ,
  NEXT_API_ROUTES.CONFIRM_USER,
  ROUTES.DEVICES,
  NEXT_API_ROUTES.UPDATE_DEVICE,
  NEXT_API_ROUTES.VERIFY_RESERVATION,
  NEXT_API_ROUTES.VERIFY_HMAC,
  NEXT_API_ROUTES.RESERVATION_LOGIN,
  NEXT_API_ROUTES.GENERATE_JWT,
  NEXT_API_ROUTES.SCAN_LIMITER,
  NEXT_API_ROUTES.CREATE_SOCIAL_USER,
  NEXT_API_ROUTES.MERGE_ACCOUNT,
  ROUTES.HOTELS,
  ROUTES.PAYMENT_STATUS,
  ROUTES.ERROR,
  NEXT_API_ROUTES.AUTHENTICATE,
  ROUTES.DISCOVERY,
];

/**
 * An array of routes that are used for authentication
 * These routes will redirect logged in users to homepage
 * @type {string[]}
 */
export const authRoutes = [ROUTES.SIGN_IN, ROUTES.MAGIC_PAGE];

/**
 * An array of routes that are used for temporary and unconfirmed user
 * @type {string[]}
 */
export const allowedRoutesForUnconfirmed = [
  ROUTES.HOMEPAGE,
  ROUTES.GUEST_EDIT,
  ROUTES.THANK_YOU,
  ROUTES.RESERVATION,
  NEXT_API_ROUTES.CONFIRM_USER,
  NEXT_API_ROUTES.RESERVATION_LOGIN,
];
